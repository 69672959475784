import { assertUnreachable } from "@lib/utils/assertUnreachable";
import { PageTypes } from "@lib/utils/types/PageTypes";
import { generateInternalLinkHref } from "app/lib/utils";
import { cn } from "app/lib/utils";
import { cva, type VariantProps } from "class-variance-authority";
import Link from "next/link";
import { MouseEventHandler, ReactNode } from "react";

type EmailLink = {
  linkType: "Email";
  email: string;
};

type PhoneLink = {
  linkType: "Phone Number";
  phoneNumber: string;
};

type InternalLink = {
  linkType: "Internal link";
  blank: boolean;
  internalLink: {
    language: string;
    pageType: PageTypes;
    parentSlug?: null | string;
    grandparentSlug?: null | string;
    slug: string | null;
  };
};

type ExternalLink = {
  linkType: "External link";
  blank: boolean;
  link: string;
};

type LinkTypeUnion = EmailLink | PhoneLink | InternalLink | ExternalLink;

export type ILink = LinkTypeUnion & {
  label: string;
};

export type ILinkBlock = {
  link: ILink;
  className?: string;
  children?: ReactNode;
  onClick?: MouseEventHandler<HTMLAnchorElement>;
} & VariantProps<typeof LinkVariants>;

const LinkVariants = cva(
  "inline-flex items-center gap-4 justify-center rounded-md text-sm font-medium text-balance ring-offset-background transition-colors focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:pointer-events-none disabled:bg-inactive disabled:text-inactive-foreground",
  {
    variants: {
      variant: {
        Primary: "bg-primary text-primary-foreground hover:bg-primary/90",
        Secondary:
          "bg-secondary text-secondary-foreground hover:bg-secondary/80",
        Tertiary: "text-white underline-offset-4 hover:underline",
        Navigation: "text-primary underline-offset-4 hover:underline",
        Outline:
          "border bg-transparent border-accent text-accent hover:bg-accent hover:text-accent-foreground",
      },
      size: {
        default: "h-10 px-4 rounded-[8px] py-2 ",
        sm: "h-9 rounded-md px-3",
        lg: "h-11 rounded-md text-4xl font-bold px-8",
        icon: "h-10 w-10",
      },
    },
    defaultVariants: {
      variant: "Primary",
      size: "default",
    },
  }
);

export function LinkBlock({
  link,
  variant,
  size,
  className,
  children,
  onClick,
}: ILinkBlock) {
  const classNames = cn(
    LinkVariants({ variant: variant, size: size }),
    className
  );

  switch (link.linkType) {
    case "Phone Number":
      return (
        <a
          href={`tel:${link.phoneNumber.replace(/\s/g, "")}`}
          className={classNames}
          onClick={onClick}
        >
          {children}
          {link.label}
        </a>
      );
    case "Internal link":
      return (
        <Link
          target={link.blank ? "_blank" : ""}
          href={generateInternalLinkHref(
            link.internalLink.pageType,
            link.internalLink.slug,
            link.internalLink.parentSlug,
            link.internalLink.grandparentSlug,
            link.internalLink.language
          )}
          className={classNames}
          onClick={onClick}
        >
          {children}
          {link.label}
        </Link>
      );
    case "External link":
      return (
        <Link
          target={link.blank ? "_blank" : ""}
          rel="noopener noreferrer"
          href={link.link}
          className={classNames}
          onClick={onClick}
        >
          {children}
          {link.label}
        </Link>
      );
    case "Email":
      return (
        <a
          href={`mailto:${link.email}`}
          className={classNames}
          onClick={onClick}
        >
          {children}
          {link.label}
        </a>
      );
    default:
      return assertUnreachable(link);
  }
}
